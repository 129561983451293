import './dayjs.extend';
import { ApolloProvider } from '@apollo/client';
import AppRoutes from './AppRoutes';
import apolloClient from './apolloClient';
import ConfirmModalProvider, { ConfirmModalCommon as ConfirmModal } from './contexts/ConfirmModal';
import NotificationProvider, { NotificationCommon as Notification } from './contexts/Notification';
import UserSessionProvider from './contexts/UserSession';
import SubscriptionManager from './subscriptions';

const App = () => (
    <ApolloProvider client={apolloClient}>
        <UserSessionProvider>
            <NotificationProvider>
                <ConfirmModalProvider>
                    <SubscriptionManager />
                    <AppRoutes />
                    <Notification />
                    <ConfirmModal />
                </ConfirmModalProvider>
            </NotificationProvider>
        </UserSessionProvider>
    </ApolloProvider>
);

export default App;
