import { XMarkIcon } from '@heroicons/react/24/outline';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { primary } from '../../../utils/theme';

type CloseButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const CloseButton = (props: CloseButtonProps) => (
    <button
        // eslint-disable-next-line max-len
        className={`rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 ${primary.focusRingColor} focus:ring-offset-2`}
        type="button"
        {...props}
    >
        <span className="sr-only">Close</span>
        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
    </button>
);

export default CloseButton;
