import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
    XCircleIcon,
} from '@heroicons/react/24/outline';
import { isNil } from 'lodash/fp';
import { useEffect } from 'react';
import Button from '../Button';

export interface NotificationProps {
    type: 'success' | 'warning' | 'error' | 'info';
    message: string;
    description?: string;
    duration?: number | null;
    show: boolean;
    onClose: () => void;
}
const Notification = ({ type, message, description, duration, show, onClose }: NotificationProps) => {
    useEffect(() => {
        if (!isNil(duration)) {
            setTimeout(() => {
                onClose();
            }, duration);
        }
    }, [duration, onClose]);

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/*
                        // eslint-disable-next-line max-len
                        Notification panel, dynamically insert this into the live region when it needs to be displayed
                     */}
                    <Transition show={show}>
                        <div
                            // eslint-disable-next-line max-len
                            className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0"
                        >
                            <div className="p-4" data-cy="notification">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {type === 'warning' && (
                                            <ExclamationTriangleIcon
                                                aria-hidden="true"
                                                className="h-6 w-6 text-yellow-400"
                                            />
                                        )}
                                        {type === 'error' && (
                                            <XCircleIcon aria-hidden="true" className="h-6 w-6 text-red-400" />
                                        )}
                                        {type === 'success' && (
                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-400" />
                                        )}
                                        {type === 'info' && (
                                            <InformationCircleIcon
                                                aria-hidden="true"
                                                className="h-6 w-6 text-blue-400"
                                            />
                                        )}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">{message}</p>
                                        <p className="mt-1 text-sm text-gray-500">{description}</p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <Button className="!p-0 !text-gray-400" design="text" onClick={onClose}>
                                            <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
};

export default Notification;
