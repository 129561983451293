import { pick } from 'lodash/fp';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import Notification, { NotificationProps } from '../components/common/Notification';

type ActionOptionsProps = Pick<NotificationProps, 'message' | 'description' | 'duration'>;
type NotificationContextValue = {
    type?: NotificationProps['type'];
    close?: () => void;
    show: NotificationProps['show'];
    message?: NotificationProps['message'];
    description?: NotificationProps['description'];
    duration?: NotificationProps['duration'];
    success: (option: ActionOptionsProps) => void;
    warning: (option: ActionOptionsProps) => void;
    error: (option: ActionOptionsProps) => void;
    info: (option: ActionOptionsProps) => void;
};

const NotificationContext = createContext<NotificationContextValue | null>(null);

type State = Pick<NotificationContextValue, 'type' | 'show' | 'message' | 'description' | 'duration'>;

type SetShow = { type: 'setShow'; show: State['show'] };
type SetType = { type: 'setType'; typeValue: State['type'] };
type SetMessage = { type: 'setMessage'; message: State['message'] };
type SetDescription = { type: 'setDescription'; description: State['description'] };
type SetDuration = { type: 'setDuration'; duration: State['duration'] };
type Reset = { type: 'reset' };

type Action = SetShow | SetType | SetMessage | SetDescription | SetDuration | Reset;
const defaultState = {
    show: false,
    type: undefined,
    message: undefined,
    description: undefined,
    duration: undefined,
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'setType':
            return { ...state, type: action.typeValue };

        case 'setShow':
            return { ...state, show: action.show };

        case 'setMessage':
            return { ...state, message: action.message };

        case 'setDescription':
            return { ...state, description: action.description };

        case 'setDuration':
            return { ...state, duration: action.duration };

        case 'reset':
            return defaultState;

        default:
            return state;
    }
};

type NotificationProviderProps = { children: ReactNode };
const NotificationProvider = ({ children }: NotificationProviderProps) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    useEffect(() => {
        if (!state.show) {
            dispatch({ type: 'reset' });
        }
    }, [state.show]);

    const setCommon = useCallback(({ message, description }: ActionOptionsProps) => {
        dispatch({ type: 'setMessage', message });
        dispatch({ type: 'setDescription', description });
        dispatch({ type: 'setShow', show: true });
    }, []);

    const success = useCallback(
        (options: ActionOptionsProps) => {
            dispatch({ type: 'reset' });
            dispatch({ type: 'setType', typeValue: 'success' });
            setCommon(options);
            dispatch({ type: 'setDuration', duration: options.duration || 5000 });
        },
        [setCommon]
    );

    const error = useCallback(
        (options: ActionOptionsProps) => {
            dispatch({ type: 'reset' });
            dispatch({ type: 'setType', typeValue: 'error' });
            setCommon(options);
            dispatch({ type: 'setDuration', duration: options.duration });
        },
        [setCommon]
    );

    const warning = useCallback(
        (options: ActionOptionsProps) => {
            dispatch({ type: 'reset' });
            dispatch({ type: 'setType', typeValue: 'warning' });
            setCommon(options);
            dispatch({ type: 'setDuration', duration: options.duration || 5000 });
        },
        [setCommon]
    );

    const info = useCallback(
        (options: ActionOptionsProps) => {
            dispatch({ type: 'reset' });
            dispatch({ type: 'setType', typeValue: 'info' });
            setCommon(options);
            dispatch({ type: 'setDuration', duration: options.duration });
        },
        [setCommon]
    );

    const close = useCallback(() => {
        dispatch({ type: 'setShow', show: false });
    }, []);

    const context = useMemo(
        (): NotificationContextValue => ({
            ...state,
            success,
            warning,
            error,
            info,
            close,
        }),
        [state, success, warning, error, info, close]
    );

    return <NotificationContext.Provider value={context}>{children}</NotificationContext.Provider>;
};

const useNotification = () => {
    const context = useContext(NotificationContext);

    if (!context) {
        throw new Error('Notification must be used within a NotificationProvider');
    }

    return context;
};

export const useNotificationAction = () => {
    const context = useNotification();

    return pick(['success', 'warning', 'error', 'info', 'close'], context);
};

export const NotificationCommon = () => {
    const { show, type, message, description, close, duration } = useNotification();

    if (!show) {
        return null;
    }

    return (
        <Notification
            description={description}
            duration={duration}
            message={message || ''}
            onClose={close || (() => {})}
            show={show}
            type={type || 'success'}
        />
    );
};

export default NotificationProvider;
