import { pick } from 'lodash/fp';
import { ReactNode, createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { ModalProps } from '../components/common/Modal';

type ConfirmModalProps = Pick<ModalProps, 'open' | 'title' | 'setOpen' | 'onCancel'> & {
    content?: string;
    onConfirm: ModalProps['onOk'];
};
const ConfirmModal = ({ open, title, content, setOpen, onConfirm, onCancel }: ConfirmModalProps) => {
    const { t } = useTranslation('common');

    return (
        <Modal
            className="!max-w-96"
            dataCy="confirmation"
            okText={t('common:confirmModal.actions.confirm')}
            onCancel={onCancel}
            onOk={onConfirm}
            open={open}
            setOpen={setOpen}
            title={title}
        >
            <div>{content}</div>
        </Modal>
    );
};

type ActionOptions = { title: string; content: string; onConfirm: () => void };
export type ConfirmModalContextValue = {
    open?: ConfirmModalProps['open'];
    setOpen?: ConfirmModalProps['setOpen'];
    title?: ConfirmModalProps['title'];
    content?: ConfirmModalProps['content'];
    onConfirm?: ConfirmModalProps['onConfirm'];
    confirm: (option: ActionOptions) => void;
    cancel: () => void;
};

const ConfirmModalContext = createContext<ConfirmModalContextValue | null>(null);

type State = Pick<ConfirmModalContextValue, 'open' | 'title' | 'content' | 'onConfirm'>;
const defaultState = {
    open: false,
    title: undefined,
    content: undefined,
    onConfirm: () => {},
};

type SetOpen = { type: 'setOpen'; open: State['open'] };
type Reset = { type: 'reset' };
type SetAll = { type: 'setAll' } & State;
type Action = SetOpen | Reset | SetAll;

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'setOpen':
            return { ...state, open: action.open };

        case 'setAll':
            return pick(['open', 'title', 'content', 'onConfirm'], action);

        case 'reset':
            return defaultState;

        default:
            return state;
    }
};

type ConfirmModalProviderProps = { children: ReactNode };
const ConfirmModalProvider = ({ children }: ConfirmModalProviderProps) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const confirm = useCallback(({ title, content, onConfirm }: ActionOptions) => {
        dispatch({ type: 'reset' });
        dispatch({ type: 'setAll', title, content, onConfirm, open: true });
    }, []);

    const cancel = useCallback(() => {
        dispatch({ type: 'reset' });
    }, []);

    const context = useMemo(
        (): ConfirmModalContextValue => ({
            ...state,
            setOpen: ((val: boolean) => {
                dispatch({ type: 'setOpen', open: val });
            }) as ConfirmModalContextValue['setOpen'],
            confirm,
            cancel,
        }),
        [state, confirm, cancel]
    );

    return <ConfirmModalContext.Provider value={context}>{children}</ConfirmModalContext.Provider>;
};

const useConfirmModal = () => {
    const context = useContext(ConfirmModalContext);

    if (!context) {
        throw new Error('Confirm modal must be used within a ConfirmModalProvider');
    }

    return context;
};

export const useConfirmModalAction = () => {
    const context = useConfirmModal();

    return pick(['confirm', 'cancel'], context);
};

export const ConfirmModalCommon = () => {
    const { open, setOpen, title, content, cancel, onConfirm } = useConfirmModal();

    if (!open) {
        return null;
    }

    return (
        <ConfirmModal
            content={content}
            onCancel={() => {
                cancel();
            }}
            onConfirm={onConfirm}
            open={open}
            setOpen={setOpen || (() => {})}
            title={title}
        />
    );
};

export default ConfirmModalProvider;
