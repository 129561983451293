import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { primary, secondary } from '../../../utils/theme';
import Button from '../../common/Button';

export interface NotFoundProps {
    homePath?: string | null;
}

const NotFound = ({ homePath = '/' }: NotFoundProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    return (
        <div className="text-center">
            <p className={`text-base font-semibold ${primary.textColor}`}>404</p>
            <h1 className={`mt-4 text-3xl font-bold tracking-tight ${secondary.textColor} sm:text-5xl`}>
                {t('common:pages:404:title')}
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">{t('common:pages:404:description')}</p>
            {homePath && (
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Button design="primary" onClick={() => navigate(homePath)} size="l">
                        {t('common:pages:404:buttonSpan')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default NotFound;
